import {getNamesFromArray, getStringFromBoolean} from "@/utils/formData";

export default {
  countCells: 14,
  massive: [
    {
      dataField: 'actions',
      cols: 1,
      rows: 1,
      order: 9,
      class: 'd-flex flex-row align-start justify-center ',
      headerClass: '',
      variable: true,
      text: '',
    },
    {
      dataField: 'nameOlympic',
      cols: 1,
      rows: 1,
      text: 'Олимпиада',
      class: 'd-flex flex-row align-start justify-start ',
      headerClass: 'd-flex flex-row px-2',
      headerStyles: `min-width: 260px; justify-content: flex-start;`,
      order: 0,
      variable: true,
      template: (r) => r.olympiad?.name,
      headerTooltip: true,
      headerTooltipText: () => `Полное наименование олимпиады в соответствии с утвержденным положением об олимпиаде`,
    },
    {
      dataField: 'actual',
      cols: 1,
      rows: 1,
      text: `Входит в Перечень?`,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      headerStyles: ``,
      headerTooltip: true,
      headerTooltipText: () => `Входит ли олимпиада в Перечень олимпиад школьников и их уровни на 2022/23 учебный год, утвержденный Приказом Минобрнауки России от 30.08.2022 № 828?`,
      order: 1,
      variable: true,
      template: (r) => getStringFromBoolean(r.actual)
    },
    // {
    //   dataField: 'profiles',
    //   cols: 1,
    //   rows: 1,
    //   order: 2,
    //   variable: true,
    //   headerClass: ' ',
    //   class: 'px-2 d-flex flex-column justify-center',
    //   headerStyles: `min-width: 200px`,
    //   styles: 'position: relative;',
    //   text: `Наименования(-е) профилей(-я) олимпиады`,
    // },
    /*надо удалить пункт 10 ворд*/
    /*{
      dataField: 'startYear',
      cols: 1,
      rows: 1,
      order: 3,
      headerClass: '',
      class: `align-start`,
      variable: true,
      text: `С какого года проводится олимпиада?`,
      template: (r)=>  formatDate(r.startYear)
    },
    {
      dataField: 'isForeignPlaces',
      cols: 1,
      rows: 1,
      order: 3,
      headerClass: 'd-flex flex-row align-center',
      class: `align-start`,
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Выделялись ли на олимпиаду места для отбора иностранных граждан в пределах квоты Правительства Российской
Федерации на 2022/2023 учебный год?`,
      text: `Выделялись ли места?`,
      template: (r)=>  getStringFromBoolean(r.isForeignPlaces)
    },
    {
      dataField: 'foreignPlaces',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Какое количество мест было выделено на олимпиаду? `,
    },
    {
      dataField: 'foreignPlacesConfirmed',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Какое количество мест было освоено по олимпиаде?`,
    },
    {
      dataField: 'site',
      cols: 1,
      rows: 1,
      order: 3,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: `Официальный сайт олимпиады`,
    },*/
    {
      dataField: 'paragraphNumber',
      cols: 1,
      rows: 1,
      order: 4,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Номер пункта в положении об олимпиаде, где указано условие о возможности участия в ней иностранных граждан / комментарий об отсутствии в положении об олимпиаде ограничений на участие иностранных граждан в этой олимпиаде.`,
      text: 'Номер пункта в положении об олимпиаде про участие иностранных граждан',
    },
    {
      dataField: 'maintainerOrg',
      cols: 1,
      rows: 1,
      order: 5,
      class: `align-start`,
      headerClass: 'd-flex flex-row align-center',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Является ли ваша организация основным организатором олимпиады?`,
      text: `Организатор олимпиады?`,
      template: (data) => getStringFromBoolean(data.maintainerOrg)
    },
    {
      dataField: 'maintainerOrgName',
      cols: 1,
      rows: 1,
      order: 6,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: `Организатор`,
      headerTooltip: true,
      headerTooltipText: () => `Полное наименование основного организатора олимпиады (ввести полное наименование по ЕГРЮЛ)`,
      template: (data) => data.maintainerOrgName
    },
    {
      dataField: 'helpersOrgNames',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: `Соорганизаторы`,
      headerTooltip: true,
      headerTooltipText: () => `Полные наименования соорганизаторов олимпиады (ввести полные наименования по ЕГРЮЛ)`,
      template: (data) => data.helpersOrgNames && data.helpersOrgNames.length ? data.helpersOrgNames.join(',<br> ') : 'У олимпиады не было соорганизаторов'
    },
    {
      dataField: 'distance',
      cols: 1,
      rows: 1,
      order: 8,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Была ли возможность дистанционного участия иностранных граждан в олимпиаде?`,
      text: 'Дистанционное участие иностранных граждан?',
      template: (data) => getStringFromBoolean(data.distance)
    },
    {
      dataField: 'informChannels',
      cols: 1,
      rows: 1,
      order: 9,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Каналы информирования об олимпиаде, которые были использованы для привлечения внимания иностранных граждан к участию в олимпиаде.`,
      text: 'Каналы информирования',
      template: (data) => getNamesFromArray(data.informChannels, 'name', ', ', 'Каналы информирования об олимпиаде для привлечения иностранных граждан к участию в олимпиаде не использовались')
    },
    {
      dataField: 'advertisements',
      cols: 1,
      rows: 1,
      order: 10,
      class: `align-start`,
      headerClass: 'd-flex flex-row',
      variable: true,
      headerStyles: `min-width: 200px; justify-content: flex-start;`,
      text: 'Мероприятия по продвижению',
      headerTooltip: true,
      headerTooltipText: () => `Мероприятия по продвижению олимпиады среди иностранных граждан, которые были проведены.`,
      template: (data) => getNamesFromArray(data.advertisements, 'name', ', ', 'Мероприятия по продвижению олимпиады среди иностранных граждан не проводились')
    },
    {
      dataField: 'experts',
      cols: 1,
      rows: 1,
      order: 11,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `ФИО и квалификация международных экспертов, которые состояли в организационном комитете / методической комиссии / жюри олимпиады.`,
      text: 'ФИО и квалификация экспертов',
      template: (data) => data.experts?.length > 0 ? getNamesFromArray(data.experts, ['name', 'qualification'], ', ', 'В организационном комитете / методической комиссии / жюри олимпиады международные эксперты не состояли') : 'В организационном комитете / методической комиссии / жюри олимпиады международные эксперты не состояли'
    },
    {
      dataField: 'minKnowledgeRequired',
      cols: 1,
      rows: 1,
      order: 12,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Имелись ли требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады?`,
      text: 'Наличие требований к минимальному уровню знаний',
      template: (data) => getStringFromBoolean(data.minKnowledgeRequired)
    },
    {
      dataField: 'minKnowledgeRequiredInfo',
      cols: 1,
      rows: 1,
      order: 13,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: 'Требования к минимальному уровню знаний',
      headerTooltip: true,
      headerTooltipText: () => `Требования к минимальному уровню знаний, необходимых для успешного прохождения олимпиады.`,
      template: (data) => data.minKnowledgeRequired ? data.minKnowledgeRequiredInfo : 'Требований к минимальному уровню знаний, необходимых для успешного прохождения олимпиады, не было'
    },
    {
      dataField: 'fileId',
      cols: 1,
      rows: 1,
      order: 14,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      text: 'Положение об олимпиаде',
    },
  ]
}
